import sanitizeHtml from "sanitize-html"
/**
 * 文字列を特定の文字をそれぞれのHTMLエンコードされた等価物に置き換える。
 * 具体的には、"&", "<", ">", '"', "'"の文字をそれぞれ"`&amp;`", "`&lt;`", "`&gt;`", "`&quot;`", "`&#39;`"に置き換える。
 * @param {string} str - サニタイズされる文字列
 * @returns {string} サニタイズされた文字列
 */
export const sanitizeEncode = (str: string): string => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
}

/**
 * 文字列を特定のHTMLエンコードされた文字をそれぞれの通常の等価物に置き換える。
 * 具体的には、"`&amp;`", "`&lt;`", "`&gt;`", "`&quot;`", "`&#39;`"の文字をそれぞれ"&", "<", ">", '"', "'"に置き換える。
 * @param {string} str - デコードされる文字列
 * @returns {string} デコードされた文字列
 */
export const sanitizeDecode = (str: string): string => {
  return str
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&amp;/g, "&")
}

export const sanitizeInput = (input: string | undefined | null) => {
  if (input) return sanitizeEncode(input)
  return ""
}

type AnyPrimitive = number | string | boolean | null | undefined

interface AnyObject {
  [key: string]: AnyPrimitive | AnyObject | (AnyPrimitive | AnyObject)[]
}

type Any = AnyPrimitive | AnyObject | (AnyPrimitive | AnyObject)[]
/**
 * 引数のオブジェクト内の文字列をサニタイズして返す関数。
 * 再帰的に処理を行うので、ネストされたオブジェクトや配列内の文字列もサニタイズする。
 *
 * @param {T} value - サニタイズされる値（プリミティブ型、オブジェクト、配列）
 * @returns {T} サニタイズされた値
 * @template T - 入力値の型（AnyPrimitive、AnyObject、配列を含む）
 */
export const sanitizeObject = <T extends Any>(value: T): T => {
  if (Array.isArray(value)) {
    return value.map((elm) => sanitizeObject(elm)) as T
  }
  if (typeof value === "object" && value != null) {
    return Object.fromEntries(Object.entries(value).map(([key, val]) => [key, sanitizeObject(val)])) as T
  }
  if (typeof value === "string") {
    return sanitizeInput(value) as T
  }
  return value
}

/**
 * v-html で利用する HTML を XSS 対策でサニタイズする
 * @param htmlContent v-html に与える HTML
 */
export const sanitizeHtmlContent = (htmlContent: string | undefined): string => {
  if (!htmlContent) return ""
  const options: sanitizeHtml.IOptions = {
    allowedTags: [...sanitizeHtml.defaults.allowedTags, "img", "iframe"],
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      "*": ["style", "class", "id"],
      iframe: [
        "title",
        "src",
        "width",
        "height",
        "style",
        "frameborder",
        "allow",
        "allowfullscreen",
        "loading",
        "referrerpolicy",
      ],
    },
    allowedIframeHostnames: ["www.youtube.com", "www.youtube-nocookie.com", "www.google.com"],
  }

  return sanitizeHtml(htmlContent, options)
}
